import { BLUR_COMMAND, CLICK_COMMAND, COMMAND_PRIORITY_LOW, ElementNode, LexicalEditor, TextNode } from 'lexical';
import { FocusEvent, useEffect, useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import isURL from 'validator/lib/isURL';

import Text from 'lib/common/components/Text';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Button from 'lib/common/components/Button';

import OPEN_LINK_EDITOR_COMMAND from './constants/openLinkEditorCommand';
import EditLinkModal from './EditLinkModal';
import addProtocolToUrl from './utils/addProtocolToUrl';

export default function FloatingLinkEditor({
  editor,
  currentNode
}: {
  editor: LexicalEditor;
  currentNode: ElementNode | TextNode | null;
}) {
  const editorRef = useRef<null | HTMLDivElement>(null);
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [linkEditorVisible, setLinkEditorVisible] = useState(false);

  useEffect(() => {
    editor.getEditorState().read(() => {
      const parentNode = currentNode?.getParent();
      const isLink = $isLinkNode(currentNode) || $isLinkNode(parentNode);

      if (!editorRef?.current || !currentNode || !isLink) {
        setLinkEditorVisible(false);
      }

      if (!editorRef?.current || !currentNode) {
        return;
      }

      const linkText = currentNode?.getTextContent() || '';

      setLinkText(linkText);

      const nodeElement = editor.getElementByKey($isLinkNode(parentNode) ? parentNode.__key : currentNode.__key);

      if (!nodeElement) {
        return;
      }

      if (isLink) {
        // In some use cases when pasting links, the text is set and it's in a link element, but url is empty
        const nodeUrl = $isLinkNode(parentNode) ? parentNode.getURL() : currentNode?.getURL();
        const editableUrl = nodeUrl || addProtocolToUrl(linkText);

        setLinkUrl(editableUrl || '');
      }

      createPopper(nodeElement, editorRef.current, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
              padding: 5
            }
          },
          {
            name: 'preventOverflow'
          }
        ]
      });
    });
  }, [editorRef, currentNode]);

  useEffect(() => {
    if (!currentNode) {
      setLinkEditorVisible(false);
    }

    return mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        () => {
          const parentNode = currentNode?.getParent();
          const isLink = $isLinkNode(currentNode) || $isLinkNode(parentNode);

          setLinkEditorVisible(Boolean(isLink));

          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (event: FocusEvent) => {
          // Only hide the link editor when we've interacted with a different part of the document than this link editor
          if (event.relatedTarget === editorRef.current || editorRef.current?.contains(event.relatedTarget)) {
            return false;
          }

          setLinkEditorVisible(false);

          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        OPEN_LINK_EDITOR_COMMAND,
        (initialUrl: string) => {
          setLinkUrl(initialUrl);
          setLinkEditorVisible(Boolean(initialUrl));
          setEditMode(!initialUrl);

          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, currentNode]);

  return (
    <>
      <EditLinkModal
        editor={editor}
        initialLink={linkUrl}
        onClose={(link: string | null) => {
          setLinkUrl(link || '');
          setEditMode(false);
        }}
        open={isEditMode}
      />
      <div ref={editorRef} className={cx('link-editor', { 'link-editor--hidden': !linkEditorVisible })}>
        <div data-popper-arrow className="link-editor__arrow" />
        <div className="link-editor__header">
          <Text type="small" ellipsis>
            {linkText}
          </Text>
          <ClickableIcon
            className="link-editor__close"
            icon={faXmark}
            size={17}
            color="darkGrey"
            onClick={() => setLinkEditorVisible(false)}
          />
        </div>
        <a
          className="text anchor"
          href={linkUrl}
          target={linkUrl.startsWith('mailto:') ? void 0 : '_blank'}
          rel="noopener noreferrer"
        >
          {linkUrl}
        </a>
        <div className="next-to-10 mt-20 ">
          <Button
            className="ml-auto"
            size="small"
            styleType="SECONDARY"
            onClick={() => {
              setEditMode(true);
            }}
          >
            Edit
          </Button>
          <Button
            styleType="DANGER"
            size="small"
            onClick={() => {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </>
  );
}
